<template>
  <div
    :class="{ mobile: isMobile }"
    class="self-exclusion-modal"
    data-t="self-exclusion-modal"
  >
    <StDrawerHeader
      v-if="isMobile"
      with-close-button
      :data-t="{ root: 'title' }"
      :title="t('selfExclusionModal.title')"
      class="title"
    />
    <h2 v-else class="title">
      {{ t('selfExclusionModal.title') }}
    </h2>
    <div class="warning-paragraph">
      <StIcon class="alert-icon" name="circle-alert" size="20" />
      <p class="text">
        {{ t('selfExclusionModal.warningText') }}
      </p>
    </div>
    <ul class="list">
      <li class="list-item">{{ t('selfExclusionModal.firstBullet') }}</li>
      <li class="list-item">
        {{ t('selfExclusionModal.secondBullet') }}
      </li>
      <li class="list-item">
        {{ t('selfExclusionModal.thirdBullet', { date: endDate }) }}
      </li>
    </ul>
    <div class="buttons">
      <StButton
        class="cancel-button"
        data-t="cancel-button"
        :size="isMobile ? 'l' : 'xl'"
        type="gray"
        :label="t('selfExclusionModal.cancelButton')"
        @click="closeModal"
      />
      <StButton
        class="confirm-button"
        data-t="confirm-button"
        :size="isMobile ? 'l' : 'xl'"
        :label="t('selfExclusionModal.confirmButton')"
        @click="sendExclusion"
      />
    </div>
    <Teleport to="body">
      <div
        v-if="isOpenBlackModal"
        :class="{ mobile: isMobile }"
        class="landing-modal"
      >
        <div class="info-wrapper">
          <img class="logo" src="./images/logo.svg" alt="logo" data-t="logo" />
          <h2 class="landing-title" data-t="landing-title">
            {{ t('selfExclusionLanding.title') }}
          </h2>
          <i18n-t
            tag="p"
            keypath="selfExclusionLanding.text"
            class="landing-info"
            data-t="landing-info"
          >
            <template #date>
              <span class="end-date">{{ endDate }}</span>
            </template>
          </i18n-t>

          <StButton
            type="gray"
            class="see-you-button"
            data-t="see-you-button"
            :size="isMobile ? 'l' : 'xl'"
            :label="t('selfExclusionLanding.buttonText')"
            to="/"
          />
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
const stFetch = useRawStFetch()
const router = useRouter()
const { isMobile } = usePlatform()

const props = defineProps<{ endDate: string; expiredAt: string }>()

const isOpenBlackModal = ref(false)

function closeModal() {
  router.replace({ query: {} })
}

async function sendExclusion() {
  const { error } = await stFetch('/user/self-excluded/set', {
    method: 'post',
    body: {
      expiredAt: props.expiredAt,
    },
  })

  if (error) {
    console.error(error)
  } else {
    isOpenBlackModal.value = true
  }
}
</script>

<style scoped>
.self-exclusion-modal {
  width: 480px;
  padding: var(--spacing-300);
}

.warning-paragraph {
  display: flex;
  gap: var(--spacing-125);

  margin-bottom: var(--spacing-200);
  padding: var(--spacing-200);

  color: var(--text-warning);

  background: rgb(255 216 107 / 8%);
  border-radius: var(--border-radius-150);
}

.alert-icon {
  flex-shrink: 0;
}

.text {
  margin: 0;
  font: var(--desktop-text-sm-medium);
}

.list {
  margin: 0 0 var(--spacing-300) 0;
  padding: 0 0 0 var(--spacing-200);

  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary, rgb(255 255 255 / 64%));
  list-style: none;
}

.list-item {
  position: relative;
  margin-bottom: var(--spacing-100);
}

.list-item::before {
  content: '•';

  position: absolute;
  top: 0;
  left: -17px;

  font-size: var(--desktop-text-2lg-semibold);
}

.buttons {
  display: flex;
  gap: var(--spacing-150);
}

.confirm-button {
  width: 100%;
}

.title {
  margin: 0 0 var(--spacing-200);
  font: var(--desktop-text-xl-semibold);
}

.landing-modal {
  position: fixed;
  z-index: 10001;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: var(--background-base);
}

.info-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 640px;
  margin: 0 auto;
}

.logo {
  flex-shrink: 0;
  margin-top: var(--spacing-300);
  margin-bottom: var(--spacing-600);
  padding: var(--spacing-100);
}

.landing-title {
  margin: 0 0 var(--spacing-150);
  font: var(--desktop-text-3xl-semibold);
  text-align: center;
}

.landing-info {
  margin: 0 0 var(--spacing-600);
  font: var(--desktop-text-md-medium);
  color: var(--text-secondary);
  text-align: center;
}

.end-date {
  color: var(--text-primary);
}

.mobile {
  width: 100%;
  padding: var(--spacing-200);

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 0;
    padding: var(--spacing-150) 0;

    font: var(--mobile-title-2-semibold);
  }

  .warning-paragraph {
    padding: var(--spacing-125);
  }

  .text {
    font: var(--mobile-text-content-regular);
  }

  .list {
    margin-bottom: var(--spacing-200);
    font: var(--mobile-text-content-regular);
  }

  .logo {
    width: 120px;
    height: 30px;
    margin-top: 0;
    margin-bottom: var(--spacing-400);
    padding: var(--spacing-050);
  }

  .landing-title {
    margin-bottom: var(--spacing-100);
    font: var(--mobile-title-1-semibold);
  }

  .landing-info {
    margin-bottom: var(--spacing-400);
    font: var(--mobile-text-content-regular);
  }
}
</style>
